import { combineReducers, createStore, compose, applyMiddleware, Store } from 'redux';
import thunk from 'redux-thunk';

import { IApplicationState, TReduxActions } from '../../types/redux';
import { IApplicationContext } from '../../types/applicationContext';

import {
  abUseExperiments,
  analyticsReducer,
  filters,
  createJsonQueryReducer,
  location,
  media,
  consultantChat,
  offersData,
  preloader,
  region,
  salesStartOnboarding,
  temp,
  upcomingSale,
  user,
  configReducer,
  quickLinks,
  builderChatReducer,
  consultantCianChatReducer,
} from '../../reducers';
import { infoForTgbReducer } from '../../reducers/infoForTgb/infoForTgbReducer';
import { newbuildings } from '../../reducers/newbuildings/newbuildings';

/**
 * Хелпер для создания Redux стора
 * @param preloadedState IApplicationState - предзагруженное состояние
 */
export function createReduxStore(preloadedState: IApplicationState, context: IApplicationContext) {
  const composeEnhancers =
    typeof window === 'undefined' ? compose : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const reducers = combineReducers<IApplicationState, TReduxActions>({
    analytics: analyticsReducer,
    offersData,
    jsonQuery: createJsonQueryReducer(preloadedState),
    region,
    location,
    filters,
    user,
    temp,
    media,
    preloader,
    abUseExperiments,
    consultantChat,
    salesStartOnboarding,
    upcomingSale,
    config: configReducer,
    seoCrossLinks: noOpReducer(null),
    quickLinks,
    infoForTgb: infoForTgbReducer,
    builderChat: builderChatReducer,
    newbuildings,
    consultantCianChat: consultantCianChatReducer,
  });

  let storeReminder: Store<IApplicationState, TReduxActions>;

  const middlewares = [thunk.withExtraArgument(context)];

  const createReduxStore = () =>
    createStore(reducers, preloadedState, composeEnhancers(applyMiddleware(...middlewares)));

  if (process.env.NODE_ENV === 'development') {
    // @ts-expect-error Легаси
    if (!storeReminder) {
      storeReminder = createReduxStore();

      return storeReminder;
    }

    storeReminder.replaceReducer(reducers);

    return storeReminder;
  }

  return createReduxStore();
}
// реально any
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
function noOpReducer(defaults: any) {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  return (state: any) => state || defaults;
}
