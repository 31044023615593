import * as React from 'react';
import { useSelector } from 'react-redux';

import { BrokerConsultantBanner } from '../../components/BrokerConsultantBanner';
import { selectBrokerConsultantBannerUrl } from '../../selectors/brokerConsultantBanner';

export const BrokerConsultantBannerContainer: React.FC = () => {
  const url = useSelector(selectBrokerConsultantBannerUrl) as string;

  return <BrokerConsultantBanner url={url} />;
};
