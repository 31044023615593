import { trackOpenChatPopup } from '../../containers/ConsultantCianChat/analytics/analytics';
import { EChatRole, IChatUser } from '../../repositories/newbuilding-consultants/entities/chat_user/ChatUser';
import { getFirstGaLabel } from '../../selectors/analytics';
import { getConsultantChat } from '../../services/getConsultantChat';
import { IThunkActionCreator } from '../../types/thunk';
import { openConsultantCianChat } from './actions';

export const requestChat = (): IThunkActionCreator => {
  return async (dispatch, getState, context) => {
    const state = getState();
    const customLabel = getFirstGaLabel(state);
    const { httpApi, logger } = context;
    const data = await getConsultantChat({ httpApi, logger, parameters: { pageUrl: state.location.path } });

    /* istanbul ignore else */
    if (data && data.chatId) {
      dispatch(openConsultantCianChat(data.chatId));
      const userId = data.users.find((user: IChatUser) => user.chatRole === EChatRole.Initiator)?.userId;
      trackOpenChatPopup({ label: customLabel, chatId: data.chatId, botId: 3, userId });
    }
  };
};
