import { IAdFoxBannerOnErrorError, IAdFoxBannerOnLoadData } from '@cian/adfox-component';
import { useBooleanState } from '@cian/react-utils';

import * as React from 'react';

import { AdfoxBanner } from './AdfoxBanner';
import { useAdfoxContext } from './external/context/AdfoxContext';

import { withAdfoxPostViewAnalytics } from '@cian/postview-analytics-utils';
import { AdfoxLayout } from './internal/components/AdfoxLayout';

type AdfoxBannerContainerProps = {
  galleryPosition?: number;
  position?: number;
  onError?(error: IAdFoxBannerOnErrorError): void;
  onLoad?(data: IAdFoxBannerOnLoadData): void;
  onStub?(): void;
};

type HandleError = (error: IAdFoxBannerOnErrorError) => void;

export const AdfoxBannerContainer = React.memo<AdfoxBannerContainerProps>(function AdfoxBannerContainer(props) {
  const { position, galleryPosition, onStub, onError, onLoad } = props;

  const { state: isBannerHidden, setTrue: setBannerHidden } = useBooleanState();
  const { params, size, postViewAnalyticsData } = useAdfoxContext();

  const handleStub = React.useCallback<VoidFunction>(() => {
    onStub?.();

    setBannerHidden();
  }, [onStub, setBannerHidden]);

  const handleError = React.useCallback<HandleError>(
    error => {
      onError?.(error);

      setBannerHidden();
    },
    [onError, setBannerHidden],
  );

  return React.useMemo<JSX.Element | null>(() => {
    if (isBannerHidden) {
      return null;
    }

    const Banner = withAdfoxPostViewAnalytics(AdfoxBanner, {
      ...postViewAnalyticsData,
      galleryPosition,
      position,
    });

    return (
      <AdfoxLayout>
        <Banner {...size} params={params} onStub={handleStub} onError={handleError} onLoad={onLoad} />
      </AdfoxLayout>
    );
  }, [isBannerHidden]);
});
