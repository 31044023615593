import { IFirstMaterial } from '../../types/newbuilding';
import { IApplicationState } from '../../types/redux';
import { getNewbuildingByIdSelector } from './getNewbuildingByIdSelector';

export const getNewbuildingFirstMaterialSelector =
  (newbuildingId: number) =>
  (state: IApplicationState): IFirstMaterial | null | undefined => {
    const nb = getNewbuildingByIdSelector(newbuildingId)(state);

    return nb.firstMaterial;
  };
