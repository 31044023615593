import * as React from 'react';

import * as styles from './styles.css';

export const ConsultantCianChatButtonContainer = ({ children }: React.PropsWithChildren<{}>) => {
  return (
    <div id="cian-chat-consultant" className={styles['container']}>
      {children}
    </div>
  );
};
