import * as React from 'react';

import * as styles from './NewbuildingInfo.css';

interface INewbuildingInfoProps {
  title: React.ReactNode;
  salesLeader: React.ReactNode;
  description: React.ReactNode;
  salesStart: React.ReactNode;
}

export const NewbuildingInfo = ({ title, salesLeader, description, salesStart }: INewbuildingInfoProps) => {
  return (
    <div data-testid="newbuildingInfo" className={styles['container']}>
      <div className={styles['title']}>
        <div data-testid="newbuildingTitle">{title}</div>
        {salesLeader}
      </div>
      <div className={styles['content']}>
        <div data-testid="newbuildingMainFacts" className={styles['description']}>
          {description}
        </div>
        <div data-testid="newbuildingSalesStart" className={styles['sales-start']}>
          {salesStart}
        </div>
      </div>
    </div>
  );
};
