import * as React from 'react';

import * as styles from './styles.css';

interface IConsultantCianChatButtonProps {
  onClick(): void;
}

export const ConsultantCianChatButton = ({ onClick }: IConsultantCianChatButtonProps) => {
  return (
    <button data-testid="consultantCianChatButton" className={styles['button']} onClick={onClick}>
      <div className={styles['content']}>Написать консультанту</div>
    </button>
  );
};
