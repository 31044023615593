import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getForceHideOnboarding } from '../../../selectors/builderChat';
import { chatOpenSelector } from '../../../selectors/consultantChat';
import { useApplicationContext } from '../../../utils/applicationContext';
import { TChatOnboardingType } from '../types';
import { getChatOnboardingCookie, setChatOnboardingCookie } from '../utils/chatOnboardingCookie';
import { ONBOARDING_COOKIE_NAME_MAP, CONFIG_MAP, CHAT_ONBOARDING_TEXT } from './constants';

interface IOnboardingTooltipResult {
  open: boolean;
  onboardingText: string;
  closeHandler: () => void;
}

export const useChatOnboarding = (onboardingType: TChatOnboardingType): IOnboardingTooltipResult => {
  const { config } = useApplicationContext();

  const [open, setOpen] = useState(false);

  const isConsultantChatOpen = useSelector(chatOpenSelector);
  const forceHideOnboarding = useSelector(getForceHideOnboarding);
  const onboardingText = config.get<string>(CHAT_ONBOARDING_TEXT[onboardingType]) ?? '';

  const cookieName = ONBOARDING_COOKIE_NAME_MAP[onboardingType];

  const closeHandler = useCallback(() => {
    setOpen(false);
  }, []);

  useEffect(() => {
    const isOnboardingEnabled = config.get<boolean>(CONFIG_MAP[onboardingType]);

    if (isOnboardingEnabled && !getChatOnboardingCookie(cookieName)) {
      setOpen(true);
      setChatOnboardingCookie(cookieName);
    }
  }, [cookieName, config, onboardingType]);

  useEffect(() => {
    if (
      (onboardingType === 'consultant' && open && isConsultantChatOpen) ||
      (onboardingType === 'builder' && open && forceHideOnboarding)
    ) {
      closeHandler();
    }
  }, [isConsultantChatOpen, forceHideOnboarding, open, onboardingType, closeHandler]);

  return {
    open,
    onboardingText,
    closeHandler,
  };
};
