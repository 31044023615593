import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  closeConsultantCianChat,
  openAutorisationMotivationConsultantCianChat,
} from '../../actions/consultantCianChat';
import { ConsultantCianChatButton, ConsultantCianChatButtonContainer } from '../../components/ConsultantCianChatButton';
import {
  isOpenSelector,
  isAutorisationPopupOpenSelector,
  openedChatIdSelector,
  isEnabledSelector,
} from '../../selectors/consultantCianChat';
import { ConsultantCianChatPopup } from '../../components/ConsultantCianChatPopup';
import { AutorisationMotivationPopup } from '../../components/AutorisationMotivationPopup';
import { useConsultantCianChat } from '../../hooks/consultantCianChat/useConsultantCianChat';
import { getUser } from '../../selectors/user';
import { ConsultantCianChatOnboarding } from '../ConsultantCianChatOnboarding';
import {
  trackEntryAutorisationPopupOpen,
  trackEntryPointClick,
  trackEntryAutorisationPopupClick,
  trackChatCloseClick,
} from './analytics/analytics';
import { getCurrentJsonQuery } from '../../selectors/location';
import { getFirstGaLabel } from '../../selectors/analytics';

export const ConsultantCianChat = () => {
  const dispatch = useDispatch();
  const isEnabled = useSelector(isEnabledSelector);
  const isOpened = useSelector(isOpenSelector);
  const openedChatId = useSelector(openedChatIdSelector);
  const frameUrl = `/dialogs/?hostType=frame&type=bot&chatId=${openedChatId}`;
  const isAutorisationPopupOpen = useSelector(isAutorisationPopupOpenSelector);
  const jsonQuery = useSelector(getCurrentJsonQuery);
  const customLabel = useSelector(getFirstGaLabel);
  const user = useSelector(getUser);
  const { openChat } = useConsultantCianChat();

  const onClick = React.useCallback(() => {
    if (!user.isAuthenticated) {
      dispatch(openAutorisationMotivationConsultantCianChat());
      trackEntryAutorisationPopupOpen({ jsonQuery, label: customLabel });
    } else {
      openChat();
    }
    trackEntryPointClick({ jsonQuery });
  }, [customLabel, dispatch, jsonQuery, openChat, user.isAuthenticated]);

  const onLogInClick = React.useCallback(() => {
    openChat();
    trackEntryAutorisationPopupClick({ jsonQuery, label: customLabel });
  }, [customLabel, jsonQuery, openChat]);

  const onClose = React.useCallback(() => {
    dispatch(closeConsultantCianChat());
    trackChatCloseClick({ jsonQuery });
  }, [dispatch, jsonQuery]);

  const onLogInClose = React.useCallback(() => {
    dispatch(closeConsultantCianChat());
  }, [dispatch]);

  if (!isEnabled) {
    return null;
  }

  return (
    <>
      <ConsultantCianChatButtonContainer>
        <ConsultantCianChatOnboarding>
          <ConsultantCianChatButton onClick={onClick} />
        </ConsultantCianChatOnboarding>
      </ConsultantCianChatButtonContainer>

      <AutorisationMotivationPopup
        isAutorisationPopupOpened={isAutorisationPopupOpen}
        onClick={onLogInClick}
        onClose={onLogInClose}
      />
      {isOpened && <ConsultantCianChatPopup onClose={onClose} url={frameUrl} />}
    </>
  );
};
