import * as React from 'react';
import { useChatOnboarding } from './hooks/useChatOnboarding';
import { OnboardingTooltip } from '@cian/ui-kit';
import { ChatOnboardingContent } from '../../components/ChatOnboardingContent';

export const ConsultantCianChatOnboarding = ({ children }: React.PropsWithChildren<{}>) => {
  const { open, onboardingText, closeHandler } = useChatOnboarding();

  if (!open) {
    return <>{children}</>;
  }

  return (
    <OnboardingTooltip
      theme="gray"
      content={<ChatOnboardingContent>{onboardingText}</ChatOnboardingContent>}
      placement="top-end"
      portalElement={document.getElementById('newbuilding-search-frontend')}
      open={open}
      onClose={closeHandler}
      flip={false}
    >
      <div>{children}</div>
    </OnboardingTooltip>
  );
};
