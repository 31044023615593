import * as React from 'react';
import { Image as UIImage } from '@cian/ui-kit';
import { IconPlaceholderImages24 } from '@cian/ui-kit-design-tokens/icons/placeholder/24';
import { NoSsr } from '@cian/react-utils';

import * as styles from './Image.css';

export interface IImageProps {
  lazy?: boolean;
  noEmptyImage?: boolean;
  src?: string;
  objectFit?: 'contain' | 'cover' | 'fill';
  alt?: string;
  onClick?: () => void;
}

export const Image = (props: IImageProps) => {
  const { lazy, noEmptyImage, src, alt, objectFit } = props;

  if (!src && !noEmptyImage) {
    return (
      <div className={styles['image--empty']} data-testid="image-empty">
        <IconPlaceholderImages24 color="icon-secondary-disabled" />
      </div>
    );
  }

  if (src && !noEmptyImage && lazy) {
    return (
      <NoSsr>
        <UIImage loading="lazy" src={src} alt={alt} objectFit={objectFit} preloader={false} />
      </NoSsr>
    );
  }

  return <img className={styles['image--simple']} src={src} alt={alt} style={{ ...(objectFit && { objectFit }) }} />;
};
