import { TChatOnboardingType } from '../types';

const ONBOARDING_COOKIE_NAME_MAP: Record<TChatOnboardingType, string> = {
  builder: 'newbuilding-search-frontend.builder_chat_onboarding_shown',
  consultant: 'newbuilding-search-frontend.consultant_chat_onboarding_shown',
};

const CONFIG_MAP: Record<TChatOnboardingType, string> = {
  builder: 'Newbuilding.CianChatOnboarding.Enabled',
  consultant: 'Newbuilding.WebimChatOnboarding.Enabled',
};

const CHAT_ONBOARDING_TEXT: Record<TChatOnboardingType, string> = {
  builder: 'Newbuilding.CianChatOnboarding.Text',
  consultant: 'Newbuilding.WebimChatOnboarding.Text',
};

export { ONBOARDING_COOKIE_NAME_MAP, CONFIG_MAP, CHAT_ONBOARDING_TEXT };
