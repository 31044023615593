import * as React from 'react';

import { IStyleArgument, mergeStyles } from '@cian/utils';

/***** STYLES *****/
import * as styles from './Link.css';

export interface ILinkProps extends React.HTMLProps<HTMLAnchorElement> {
  children: React.ReactNode;

  onClick?(event: React.MouseEvent<HTMLAnchorElement>): void;

  url: string;
  linkStyle?: IStyleArgument;
  target?: string;
  title?: string;
  notChangeLocation?: boolean;
  dataMark?: string;
}

/***** COMPONENT *****/
export const Link = React.forwardRef(function Link(props: ILinkProps, ref: React.Ref<HTMLAnchorElement>) {
  const {
    children,
    id,
    onClick,
    url,
    linkStyle,
    target = 'self',
    title = '',
    notChangeLocation,
    dataMark = 'Link',
    ...htmlProps
  } = props;

  return (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a
      target={`_${target}`}
      title={title}
      id={id}
      data-testid={dataMark}
      data-mark={dataMark}
      onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
        if (!window || !location) {
          return;
        }
        event.preventDefault();

        if (onClick) {
          onClick(event);
        }

        if (notChangeLocation) {
          return;
        }

        if (target === 'blank') {
          window.open(url);
        } else {
          window.location.href = url;
        }

        return;
      }}
      href={url}
      {...mergeStyles(styles['element'], linkStyle)}
      {...htmlProps}
      ref={ref}
    >
      {children}
    </a>
  );
});
