import * as React from 'react';
import { IconProductTopSeller16 } from '@cian/ui-kit-design-tokens/icons';
import { UIText3 } from '@cian/ui-kit';

import * as styles from './styles.css';

export function SalesLeader() {
  return (
    <div className={styles['container']} data-mark="SalesLeader">
      <IconProductTopSeller16 color="decorative-accent-orange" />
      <UIText3 color="decorative-accent-orange" fontWeight="bold">
        Лидер продаж
      </UIText3>
    </div>
  );
}
