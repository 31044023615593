// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type {
  TGetOrCreateChatRequest,
  TGetOrCreateChatModel,
  IMappers,
  TGetOrCreateChatResponse,
  TGetOrCreateChatResponse_1,
  TGetOrCreateChatResponseError,
} from './types';
import type { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api';

export const defaultConfig: TGetOrCreateChatModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'POST',
  microserviceName: 'newbuilding-consultants',
  pathApi: '/v1/get-or-create-chat/',
  requestType: 'json',
} as TGetOrCreateChatModel;

export function createGetOrCreateChatModel(parameters: TGetOrCreateChatRequest): TGetOrCreateChatModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetOrCreateChatOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
  parameters: TGetOrCreateChatRequest;
}

export async function fetchGetOrCreateChat<TResponse200, TResponse400>({
  httpApi,
  config,
  mappers,
  parameters,
}: IGetOrCreateChatOptions<TResponse200, TResponse400>): Promise<
  TGetOrCreateChatResponse | TResponse200 | TResponse400
> {
  const { statusCode, response, headers } = await httpApi.fetch(createGetOrCreateChatModel(parameters), config);
  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as TGetOrCreateChatResponse_1);
    }
    if (statusCode === 400) {
      return mappers[400](response as TGetOrCreateChatResponseError);
    }
  }
  return {
    statusCode,
    response,
    headers,
  } as TGetOrCreateChatResponse;
}
