import { Button, ModalWindow, UIHeading3 } from '@cian/ui-kit';

import * as styles from './AutorisationMotivationPopup.css';

interface IAutorisationMotivationPopupProps {
  isAutorisationPopupOpened: boolean;
  onClose(): void;
  onClick(): void;
}

export const AutorisationMotivationPopup = ({
  onClick,
  onClose,
  isAutorisationPopupOpened,
}: IAutorisationMotivationPopupProps) => {
  return (
    <ModalWindow
      open={isAutorisationPopupOpened}
      width={640}
      onClose={onClose}
      content={
        <div data-testid="ChatAutorisationMotivationPopup">
          <div className={styles['img']}></div>
          <UIHeading3 textAlign="center">Войдите, чтобы получить консультацию</UIHeading3>
        </div>
      }
      footer={
        <div className={styles['footer']}>
          <Button data-testid="ChatAutorisationMotivationPopupSignIn" onClick={onClick}>
            Войти
          </Button>
        </div>
      }
    />
  );
};
