import * as React from 'react';
import { AdfoxContextProvider, AdfoxContextData } from '../../context/AdfoxContext';
import { PParams } from '../../../types';
import { AdfoxBannerContainer } from '../../../AdfoxBannerContainer';

const POST_VIEW_ANALYTICS_DATA: AdfoxContextData['postViewAnalyticsData'] = {
  place: 'additional_filters',
  place__DEPRECATED: 'newbuildingsSearch',
  name__DEPRECATED: 'Listing_filter_more',
};
const P_PARAMS: PParams = { p1: 'crzye', p2: 'fmsa' };
const SIZE: AdfoxContextData['size'] = { width: '100%', minHeight: '100px' };

type TAdfoxBannerAdditionalFiltersProps = {
  onError: () => void;
  onLoad: () => void;
};

export const AdfoxBannerAdditionalFilters = React.memo<TAdfoxBannerAdditionalFiltersProps>(
  function AdfoxBannerAdditionalFilters({ onError, onLoad }) {
    return (
      <AdfoxContextProvider postViewAnalyticsData={POST_VIEW_ANALYTICS_DATA} size={SIZE} pParams={P_PARAMS}>
        <AdfoxBannerContainer onError={onError} onLoad={onLoad} />
      </AdfoxContextProvider>
    );
  },
);
