import * as React from 'react';

import { Container } from '../../components/Container';
import { SIZE_4, SIZE_16 } from '../../constants/sizes';
import { Text } from '../../components/Text';

import { INewbuilding } from '../../types/newbuilding';

import { UndergroundIcon } from '../UndergroundIcon';
import { Link } from '../../components/Link';
import { MIDDLE_DOT, NO_BREAK_SPACE } from '../../constants/symbolsUnicode';

export interface IMetroInfoProps {
  newbuilding: INewbuilding;
  isPromo?: boolean;
}

export function getMetroInfo(props: IMetroInfoProps) {
  if (props.newbuilding && props.newbuilding.undergroundInfo) {
    const { calcTimeWalk, calcTimeCar, name, lineColor, fullUrl } = props.newbuilding.undergroundInfo;
    const regionId = props.newbuilding.regionId;
    const isPromo = props.isPromo;

    return (
      <Container display="inline-flex">
        <Container display="inline-flex" dataMark="MetroInfo">
          <Link dataMark="underground" url={fullUrl || ''} target="blank">
            <Container display="inline-flex" margin={`0 ${SIZE_4} 0 0`}>
              <Container
                display="inline-flex"
                color={`#${lineColor}`}
                alignItems={'center'}
                width={SIZE_16}
                margin={`0 ${SIZE_4} 0 0`}
              >
                <UndergroundIcon regionId={regionId} />
              </Container>
              <Text color={isPromo ? 'gray_6' : 'black'} colorHovered={isPromo ? 'blue_2' : 'red'}>
                {name}
              </Text>
            </Container>
          </Link>
        </Container>
        {isPromo && (
          <Container margin={`0 ${SIZE_4} 0 0`} display="inline-block">
            <Text color="gray_6">{MIDDLE_DOT}</Text>
          </Container>
        )}
        <Text color="gray_4">
          {calcTimeWalk && calcTimeWalk <= 20
            ? `${calcTimeWalk}${NO_BREAK_SPACE}мин. пешком`
            : `${calcTimeCar}${NO_BREAK_SPACE}мин.${NO_BREAK_SPACE}на${NO_BREAK_SPACE}транспорте`}
        </Text>
      </Container>
    );
  }

  return null;
}
