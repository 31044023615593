import * as React from 'react';
import { connect } from 'react-redux';
import { NATIVE_TGB_POSITIONS } from '../../constants/adfox';
import { selectAdditionalAdfoxPuids } from '../../selectors/adfox';
import { TPuidMap } from '../../types/adfox';
import { Container } from '../../components/Container';
import { GKCard } from './GKCard';
import { BannerContainer } from './BannerContainer';
import { trackingActionToCardNewbuilding } from '../../actions/analytics';
import { IApplicationState } from '../../types/redux';
import { compose } from 'ramda';
import { IVisitedOffersContext, withVisitedOffersContext } from '@cian/frontend-visited-offers-widget';
import { getAggregateRating, getReviewsCount } from '../../selectors/seo';
import { getAllNewbuildingIdsSelector } from '../../selectors/newbuilding/getAllNewbuildingIdsSelector';
import { INewbuildingsState } from '../../types/newbuilding/newbuildingsState';
import { AdfoxBannerParallaxBetweenOffers, AdfoxBannerTGBBetweenOffers } from '../../components/AdfoxBanner';

interface IOffersBodyStateProps {
  page: number;
  additionalPuids: TPuidMap;
  aggregateRating?: number | null;
  reviewsCount?: number | null;
  allNewbuildingsId: INewbuildingsState['allIds'];
}

interface IOffersBodyDispatchProps {
  trackingToCardNewbuilding(): void;
}

type TOffersBodyProps = IOffersBodyStateProps & IOffersBodyDispatchProps & IVisitedOffersContext;

class OffersBody extends React.PureComponent<TOffersBodyProps> {
  public render() {
    const { page, aggregateRating, reviewsCount, allNewbuildingsId } = this.props;

    return (
      <Container dataMark="OffersBody" dataId="offers_body_container">
        {Boolean(aggregateRating) && <meta itemProp="aggregateRating" content={`${aggregateRating}`} />}
        {Boolean(reviewsCount) && <meta itemProp="reviewsCount" content={`${reviewsCount}`} />}
        {allNewbuildingsId.map((newbuildingId, index) => (
          <React.Fragment key={newbuildingId}>
            {index === 3 && page === 1 && <BannerContainer />}
            {NATIVE_TGB_POSITIONS.includes(index) && <AdfoxBannerTGBBetweenOffers position={index} />}
            {index === 10 && <AdfoxBannerParallaxBetweenOffers position={index} />}

            <GKCard newbuildingId={newbuildingId} onCardClick={this.handleCardClick} />
          </React.Fragment>
        ))}
      </Container>
    );
  }

  private handleCardClick = (offerId: number) => {
    const { trackingToCardNewbuilding, setOfferVisited } = this.props;

    trackingToCardNewbuilding();
    setOfferVisited(offerId);
  };
}

const mapStateToProps = (state: IApplicationState): IOffersBodyStateProps => ({
  page: state.location.page,
  additionalPuids: selectAdditionalAdfoxPuids(state),
  aggregateRating: getAggregateRating(state),
  reviewsCount: getReviewsCount(state),
  allNewbuildingsId: getAllNewbuildingIdsSelector(state),
});

const mapDispatchToProps: IOffersBodyDispatchProps = {
  trackingToCardNewbuilding: trackingActionToCardNewbuilding,
};

export function mapContextToProps({ setOfferVisited }: IVisitedOffersContext) {
  return {
    setOfferVisited,
  };
}

export const OffersBodyContainer = compose(
  withVisitedOffersContext(mapContextToProps),
  connect(mapStateToProps, mapDispatchToProps),
)(OffersBody) as React.ComponentType<{}>;
