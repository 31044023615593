import { makeContextModule } from '@cian/react-utils';
import * as React from 'react';

import { useAdfoxParams } from './internal/hooks/useAdfoxParams';

import type { AdfoxContextData } from './types';
import { PParams } from '../../../types';

const contextData = makeContextModule<AdfoxContextData>({ contextName: 'AdfoxContextData' });

const {
  Context: { Provider },
} = contextData;

export const { useContext: useAdfoxContext, withContext: withAdfoxContext, Context: AdfoxContext } = contextData;

export type AdfoxContextProviderProps = Omit<AdfoxContextData, 'params' | 'isHidden'> & {
  children: JSX.Element;
  pParams: PParams;
};

export const AdfoxContextProvider: React.FC<AdfoxContextProviderProps> = props => {
  const { children, pParams, size, postViewAnalyticsData } = props;

  const params = useAdfoxParams(pParams);

  const contextValue = React.useMemo(
    (): AdfoxContextData => ({
      postViewAnalyticsData,
      params,
      size,
    }),
    [params, postViewAnalyticsData, size],
  );

  return <Provider value={contextValue}>{children}</Provider>;
};
