import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { isOpenSelector, isAutorisationPopupOpenSelector } from '../../../selectors/consultantCianChat';
import { useApplicationContext } from '../../../utils/applicationContext';
import { getChatOnboardingCookie, setChatOnboardingCookie } from '../utils/chatOnboardingCookie';

interface IOnboardingTooltipResult {
  open: boolean;
  onboardingText: string;
  closeHandler: () => void;
}

export const useChatOnboarding = (): IOnboardingTooltipResult => {
  const { config } = useApplicationContext();

  const [open, setOpen] = useState(false);

  const isConsultantChatOpen = useSelector(isOpenSelector);
  const isConsultantAutorisationPopupChatOpen = useSelector(isAutorisationPopupOpenSelector);
  const onboardingText = 'Консультант бесплатно подберёт вам квартиру в новостройке и ответит на вопросы';

  const cookieName = 'newbuilding-search-frontend.consultant_cian_chat_onboarding_shown';

  const closeHandler = useCallback(() => {
    setOpen(false);
  }, []);

  useEffect(() => {
    const isOnboardingEnabled = config.get<boolean>('Newbuilding.CianKpnChatOnboarding.Enabled') || false;

    if (isOnboardingEnabled && !getChatOnboardingCookie(cookieName)) {
      setOpen(true);
      setChatOnboardingCookie(cookieName);
    }
  }, [cookieName, config]);

  useEffect(() => {
    if ((open && isConsultantChatOpen) || (open && isConsultantAutorisationPopupChatOpen)) {
      closeHandler();
    }
  }, [closeHandler, isConsultantAutorisationPopupChatOpen, isConsultantChatOpen, open]);

  return {
    open,
    onboardingText,
    closeHandler,
  };
};
