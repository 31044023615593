import * as React from 'react';
import { AdfoxContextProvider, AdfoxContextData } from '../../context/AdfoxContext';
import { PParams } from '../../../types';
import { AdfoxBannerContainer } from '../../../AdfoxBannerContainer';

const POST_VIEW_ANALYTICS_DATA: AdfoxContextData['postViewAnalyticsData'] = {
  place: 'between_offers',
  place__DEPRECATED: 'native_tgb',
  name__DEPRECATED: 'Listing_native_TGB',
  extra__DEPRECATED: {
    offerType: 'TGB_banner',
  },
};
const P_PARAMS: PParams = { p1: 'cusvh', p2: 'fkhj' };
const SIZE: AdfoxContextData['size'] = { width: '100%', minHeight: '248px' };

type TAdfoxBannerTGBBetweenOffersProps = {
  position: number;
};

export const AdfoxBannerTGBBetweenOffers = React.memo<TAdfoxBannerTGBBetweenOffersProps>(
  function AdfoxBannerTGBBetweenOffers({ position }) {
    return (
      <AdfoxContextProvider postViewAnalyticsData={POST_VIEW_ANALYTICS_DATA} size={SIZE} pParams={P_PARAMS}>
        <AdfoxBannerContainer position={position} />
      </AdfoxContextProvider>
    );
  },
);
