import {
  IconMapHighway16,
  IconMapMetroEkb16,
  IconMapMetroKzn16,
  IconMapMetroMsk16,
  IconMapMetroNN16,
  IconMapMetroNovosib16,
  IconMapMetroSam16,
  IconMapMetroSpb16,
  IconMapTrain16,
  ISVGIconProps,
} from '@cian/ui-kit-design-tokens/icons';

import { CSSProperties, FC, cloneElement } from 'react';

import * as styles from './TransportIcon.css';
import { TransportTypes } from '../../../../../types/TransportTypes';

type UndergroundIconProps = {
  iconType: TransportTypes;
  iconColor: string | null;
};

export const TransportIcon: FC<UndergroundIconProps> = props => {
  const { iconType, iconColor } = props;

  let icon = ((): JSX.Element => {
    switch (iconType) {
      case 'spb':
        return <IconMapMetroSpb16 />;
      case 'ekb':
        return <IconMapMetroEkb16 />;
      case 'kzn':
        return <IconMapMetroKzn16 />;
      case 'nsk':
        return <IconMapMetroNovosib16 />;
      case 'smr':
        return <IconMapMetroSam16 />;
      case 'nn':
        return <IconMapMetroNN16 />;
      case 'mow':
        return <IconMapMetroMsk16 />;
      case 'highway':
        return <IconMapHighway16 />;
      case 'railway':
        return <IconMapTrain16 />;
    }
  })();

  icon = cloneElement(icon, { 'data-testid': `icon-${iconType}` });

  const style: CSSProperties = {};

  if (iconColor) {
    icon = cloneElement<ISVGIconProps>(icon, { color: 'current_color' });
    style.color = iconColor;
  }

  return (
    <div className={styles['container']} style={style}>
      {icon}
    </div>
  );
};
