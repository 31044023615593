import { selectBrokerConsultantBannerUrl } from './selectBrokerConsultantBannerUrl';
import { IApplicationState } from '../../types/redux';
import { getUser } from '../user';

export function selectBrokerConsultantBannerAvailable(state: IApplicationState) {
  const user = getUser(state);
  const isAgent = user.isAuthenticated && user.isAgent;

  return Boolean(selectBrokerConsultantBannerUrl(state)) && !isAgent;
}
