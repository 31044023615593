import { Button, UIHeading1, UIText1 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './BrokerConsultantBanner.css';
import { NO_BREAK_SPACE } from '../../constants/symbolsUnicode';

interface IBrokerConsultantBannerProps {
  url: string;
}

export const BrokerConsultantBanner: React.FC<IBrokerConsultantBannerProps> = ({ url }) => {
  return (
    <a href={url} target="_blank" rel="noreferrer" className={styles['container']} data-testid="BrokerConsultantBanner">
      <div className={styles['inner']}>
        <UIHeading1>
          Подберём и{NO_BREAK_SPACE}поможем купить квартиру в{NO_BREAK_SPACE}новостройке. Бесплатно!
        </UIHeading1>
        <div className={styles['description-wrapper']}>
          <UIText1>Найдём жильё под{NO_BREAK_SPACE}ваш запрос</UIText1>
        </div>
        <div>
          <Button>О сервисе</Button>
        </div>
      </div>
    </a>
  );
};
