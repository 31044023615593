import * as React from 'react';
import { useSelector } from 'react-redux';
import {
  getNewbuildingNameByIdSelector,
  getNewbuildingSalesLeaderByIdSelector,
  getNewbuildingSalesStartLabelSelector,
  getNewbuildingUrlByIdSelector,
  getNewbuildingFZ214Selector,
  getNewbuildingSpecialStatusDisplaySelector,
  getNewbuildingOfferTypesSelector,
  getNewbuildingFirstMaterialSelector,
} from '../../selectors/newbuilding';
import { Link, UIText2 } from '@cian/ui-kit';
import { SalesLeader } from '../../components/SalesLeader';
import { MIDDLE_DOT, NO_BREAK_SPACE } from '../../constants/symbolsUnicode';
import { SalesStartLabelContainer } from '../SalesStartLabelContainer';
import { NewbuildingInfo } from '../../components/NewbuildingInfo/NewbuildingInfo';

interface INewbuildingInfoProps {
  newbuildingId: number;
  onCardClick(offerId: number): void;
}

export const NewbuildingInfoContainer = (props: INewbuildingInfoProps) => {
  const { newbuildingId, onCardClick } = props;
  const name = useSelector(getNewbuildingNameByIdSelector(newbuildingId));
  const url = useSelector(getNewbuildingUrlByIdSelector(newbuildingId));
  const isSalesLeader = useSelector(getNewbuildingSalesLeaderByIdSelector(newbuildingId));
  const salesStartLabel = useSelector(getNewbuildingSalesStartLabelSelector(newbuildingId));
  const isFZ214 = useSelector(getNewbuildingFZ214Selector(newbuildingId));
  const specialStatusDisplay = useSelector(getNewbuildingSpecialStatusDisplaySelector(newbuildingId));
  const offerTypes = useSelector(getNewbuildingOfferTypesSelector(newbuildingId));
  const firstMaterial = useSelector(getNewbuildingFirstMaterialSelector(newbuildingId));

  const replaceSpacesToNbsp = (text: string) => (text || '').replace(new RegExp(' ', 'g'), NO_BREAK_SPACE);

  return (
    <NewbuildingInfo
      title={
        <Link href={url} target="_blank" fontSize="L" fontWeight="bold" onClick={() => onCardClick(newbuildingId)}>
          {name}
        </Link>
      }
      salesLeader={isSalesLeader && <SalesLeader />}
      description={
        <UIText2>
          {replaceSpacesToNbsp(specialStatusDisplay)}
          {offerTypes && ` ${MIDDLE_DOT}${NO_BREAK_SPACE}${offerTypes}`}
          {firstMaterial && ` ${MIDDLE_DOT}${NO_BREAK_SPACE}${firstMaterial.name}`}
          {isFZ214 && ` ${MIDDLE_DOT}${NO_BREAK_SPACE}оформление по${NO_BREAK_SPACE}214-ФЗ`}
        </UIText2>
      }
      salesStart={
        salesStartLabel && (
          <SalesStartLabelContainer
            newbuildingId={newbuildingId}
            description={`${MIDDLE_DOT}${NO_BREAK_SPACE}${salesStartLabel.toLowerCase()}`}
          />
        )
      }
    />
  );
};
