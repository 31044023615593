import { getSeparateUrl } from '@cian/newbuilding-utils';
import { IThunkActionCreator } from '../../types/thunk';
import { IJsonQuery } from '../../utils/filters';
import { QUERY_PARAM_NAME_PAGE, PATH_PREFIX_NON_FIRST_PAGE } from '../../constants/config';
import { IOffersDataState } from '../../types/offersData';

export enum ELocationActions {
  setPage = 'location/setPage',
}

export interface ISetLocationPageParams {
  page?: number;
  path?: string;
  canUseHiddenBase?: boolean;
  jsonQuery?: IJsonQuery;
}

export interface ISetPageAction {
  type: ELocationActions;
  payload: ISetLocationPageParams;
}

export function setPage(
  page: number,
  offersData: IOffersDataState,
  jsonQuery: IJsonQuery,
  canUseHiddenBase: boolean = false,
): IThunkActionCreator {
  return dispatch => {
    const { seo, queryString, fullUrl } = offersData;
    const { path: newPath } = getSeparateUrl(fullUrl);
    const query = (seo && seo.query) || queryString;
    const queryPath = `?${query}&${QUERY_PARAM_NAME_PAGE}=${page}`;
    const path = page === 1 ? newPath : `/${PATH_PREFIX_NON_FIRST_PAGE}${queryPath}`;
    dispatch(
      setLocationPage({
        path,
        page,
        canUseHiddenBase,
        jsonQuery,
      }),
    );
  };
}

export function setLocationPage(payload: ISetLocationPageParams): ISetPageAction {
  return {
    payload,
    type: ELocationActions.setPage,
  };
}
