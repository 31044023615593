import * as React from 'react';
import { IconButton, Spinner } from '@cian/ui-kit';
import { IconActionClose16 } from '@cian/ui-kit-design-tokens/icons';

import * as styles from './ConsultantCianChatPopup.css';

interface IConsultantCianChatPopupProps {
  url?: string;
  onClose(): void;
}

export const ConsultantCianChatPopup: React.FC<IConsultantCianChatPopupProps> = ({ url, onClose }) => {
  const [isLoading, setIsLoading] = React.useState(true);

  const onLoad = () => setIsLoading(false);

  return (
    <div className={styles['container']} data-testid="ConsultantCianChatPopup">
      <span className={styles['close']}>
        <IconButton icon={<IconActionClose16 />} data-testid="ConsultantCianChatPopupCloseButton" onClick={onClose} />
      </span>
      {isLoading && (
        <span className={styles['spinner']} data-testid="spinner">
          <Spinner size={16} />
        </span>
      )}
      {url && <iframe className={styles['iframe']} src={url} onLoad={onLoad} onError={onLoad} />}
    </div>
  );
};
