import { IApplicationState } from '../../types/redux';

export const getFirstGaLabel = (state: IApplicationState): string => {
  const newbuildingId = state.newbuildings.allIds[0];

  if (!newbuildingId) {
    return '';
  }

  const rc = state.newbuildings.byId[newbuildingId];

  return rc?.gaLabels?.full || '';
};
