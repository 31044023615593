import { IApplicationState } from '../../types/redux';
import { getNewbuildingByIdSelector } from './getNewbuildingByIdSelector';

export const getNewbuildingSalesStartLabelSelector =
  (newbuildingId: number) =>
  (state: IApplicationState): string | null | undefined => {
    const nb = getNewbuildingByIdSelector(newbuildingId)(state);

    return nb.salesStartLabel;
  };
