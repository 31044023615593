import * as React from 'react';
import { useSelector } from 'react-redux';
import { GkCardLayout } from '../../components/GKCardLayout';
import { MIDDLE_DOT, NO_BREAK_SPACE, SPACE_SYMBOL } from '../../constants/symbolsUnicode';
import { getIsNewbuildingHiddenByIdSelector, getNewbuildingByIdSelector } from '../../selectors/newbuilding';
import { getNewbuildingIsUpcomingSale } from '../../selectors/getNewbuildingIsUpcomingSale';
import { getNewbuildingUrl } from '../../selectors/getNewbuildingUrl';
import { Container } from '../../components/Container';
import { Text } from '../../components/Text';
import { SIZE_16, SIZE_216, SIZE_40, SIZE_8 } from '../../constants/sizes';
import { CardLabelList } from '../../components/CardLabelList';
import { ReviewLinkContainer } from '../ReviewLinkContainer';
import { getMetroInfo } from './getMetroInfo';
import { getGeoDistance } from './getGeoDistance';
import { AddressLine } from './addressLine';
import { Advertises } from './Advertise';
import { UpcomingSaleButtonContainer } from '../UpcomingSaleButtonContaier';
import { ShowPhoneButtonContainer } from './ShowPhoneButtonContainer';
import { MessageButtonContainer } from '../MessageButtonContainer';
import { FavoriteButton } from './FavoriteButton';
import { RoomCounts } from './RoomCounts';
import { isNewbuildingInLeadFactory } from '../../utils/helpers';
import { CianInfo } from './CianInfo';
import { BuilderInfo } from './BuilderInfo';
import { IApplicationState } from '../../types/redux';
import { CarouselContainer } from './Carousel';
import { NewbuildingInfoContainer } from './NewbuildingInfo';

interface IGKCardProps {
  newbuildingId: number;
  onCardClick(offerId: number): void;
}

export const GKCard = (props: IGKCardProps) => {
  const { newbuildingId, onCardClick } = props;
  const urlFinisher = useSelector((state: IApplicationState) => state.location.path);
  const newbuilding = useSelector(getNewbuildingByIdSelector(newbuildingId));
  const isNewbuildingHidden = useSelector(getIsNewbuildingHiddenByIdSelector(newbuildingId));

  const handleCardClick = React.useCallback(() => {
    onCardClick(newbuildingId);
  }, [newbuildingId, onCardClick]);

  /* istanbul ignore next */
  if (!newbuilding || isNewbuildingHidden) {
    return null;
  }

  const newbuildingUrl = getNewbuildingUrl(newbuilding);
  const isUpcomingSale = getNewbuildingIsUpcomingSale(newbuilding);

  const hasProblem = Boolean(newbuilding.isProblem && newbuildingUrl);

  const getDescription = (text: string) => (text || '').replace(/<h3.*?<\/h3>/gm, '');

  return (
    <GkCardLayout>
      <CarouselContainer newbuildingId={newbuildingId} />
      <Container flex="1 1 100%" padding={`0 ${SIZE_16}`}>
        <NewbuildingInfoContainer onCardClick={handleCardClick} newbuildingId={newbuildingId} />
        <CardLabelList labels={newbuilding.labels} hasProblem={hasProblem} url={newbuildingUrl} id={newbuilding.id} />
        <ReviewLinkContainer newbuildingId={newbuilding.id} newbuildingUrl={newbuildingUrl} newbuilding={newbuilding} />
        <div data-testid="geo">
          {getMetroInfo({ newbuilding })}
          {newbuilding.seoGeoDistance && newbuilding.undergroundInfo && (
            /* istanbul ignore next */ <Text color="gray_4">
              {NO_BREAK_SPACE}
              {MIDDLE_DOT}
              {SPACE_SYMBOL}
            </Text>
          )}
          {getGeoDistance(newbuilding, urlFinisher)}
          {AddressLine({ newbuilding })}
        </div>
        <Container height={SIZE_40} overflow="hidden">
          <div data-testid="description">
            <Text display="inline-block" rowEllipsis>
              {/* eslint-disable-next-line react/no-danger */}
              <span dangerouslySetInnerHTML={{ __html: getDescription(newbuilding.description) }} />
            </Text>
          </div>
        </Container>
        {!isUpcomingSale && (
          <Advertises
            fromAgentsPropsCount={newbuilding.fromAgentsPropsCount}
            fromDeveloperPropsCount={newbuilding.fromDeveloperPropsCount}
            fromDevelopersRooms={newbuilding.fromDevelopersRooms}
          />
        )}
        <Container margin={`${SIZE_16} 0 0`} display="flex" flexWrap="wrap-reverse" rowGap={SIZE_16}>
          <Container
            data-testid="actionButtons"
            noPrint
            margin={`0 ${SIZE_8} 0 0`}
            display="flex"
            flexWrap="wrap"
            rowGap={SIZE_8}
          >
            <Container margin={`0 ${SIZE_8} 0 0`}>
              {isUpcomingSale ? (
                <UpcomingSaleButtonContainer newbuildingId={newbuilding.id} gaLabel={newbuilding.gaLabels.full} />
              ) : (
                <ShowPhoneButtonContainer newbuildingId={newbuilding.id} />
              )}
            </Container>
            <MessageButtonContainer newbuilding={newbuilding} />
            <FavoriteButton
              isFavorite={Boolean(newbuilding.isFavorite)}
              newbuildingId={newbuilding.id}
              gaLabelFull={newbuilding.gaLabels.full}
            />
          </Container>
          {!isUpcomingSale && (
            <RoomCounts
              fromDeveloperPropsLink={newbuilding.fromDeveloperPropsLink}
              fromAgentsPropsLink={newbuilding.fromAgentsPropsLink}
              fromAgentsPropsCount={newbuilding.fromAgentsPropsCount}
              fromAgentsCountsDisplay={newbuilding.fromAgentsCountsDisplay}
              fromDeveloperPropsCount={newbuilding.fromDeveloperPropsCount}
            />
          )}
        </Container>
      </Container>
      <Container width={SIZE_216} flex="0 0 auto" position={'relative'}>
        {isNewbuildingInLeadFactory(newbuilding) ? <CianInfo /> : <BuilderInfo builders={newbuilding.builders} />}
      </Container>
    </GkCardLayout>
  );
};
