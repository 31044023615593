import * as React from 'react';
import { useSelector } from 'react-redux';

import { selectBrokerConsultantBannerAvailable } from '../../selectors/brokerConsultantBanner';
import { isDiscountMortgageBannerEnabled } from '../../selectors/discountMortgage';
import { getNewbuildingFlightBannerAvailable } from '../../selectors/newbuildingFlight';
import { DiscountMortgageBannerContainer } from '../DiscountMortgageBannerContainer';
import { FlightBannerContainer } from '../FlightBannerContainer';
import { NewbuildingConsultBannerContainer } from '../NewbuildingConsultBanner';
import { BrokerConsultantBannerContainer } from '../BrokerConsultantBanner';

export const BannerContainer = () => {
  const isNewbuildingFlightBannerAvailable = useSelector(getNewbuildingFlightBannerAvailable);
  const isDiscountMortgageBannerAvailable = useSelector(isDiscountMortgageBannerEnabled);
  const isBrokerConsultantBannerAvailable = useSelector(selectBrokerConsultantBannerAvailable);

  if (isNewbuildingFlightBannerAvailable) {
    return <FlightBannerContainer />;
  }

  if (isDiscountMortgageBannerAvailable) {
    return <DiscountMortgageBannerContainer />;
  }

  // TODO В CD-229488 Удалить старый баннер КпН и убрать broker из имен компонентов, селектора и признака.
  if (isBrokerConsultantBannerAvailable) {
    return <BrokerConsultantBannerContainer />;
  }

  return <NewbuildingConsultBannerContainer />;
};
