import * as React from 'react';
import { AdfoxContextProvider, AdfoxContextData } from '../../context/AdfoxContext';
import { PParams } from '../../../types';
import { AdfoxBannerContainer } from '../../../AdfoxBannerContainer';

const POST_VIEW_ANALYTICS_DATA: AdfoxContextData['postViewAnalyticsData'] = {
  place: 'between_offers',
  place__DEPRECATED: 'newbuildingsSearch',
  name__DEPRECATED: 'Listing_parallax',
};
const P_PARAMS: PParams = { p1: 'dazao', p2: 'y' };
const SIZE: AdfoxContextData['size'] = { width: '100%', minHeight: '500px' };

type TAdfoxBannerParallaxBetweenOffersProps = {
  position: number;
};

export const AdfoxBannerParallaxBetweenOffers = React.memo<TAdfoxBannerParallaxBetweenOffersProps>(
  function AdfoxBannerParallaxBetweenOffers({ position }) {
    return (
      <AdfoxContextProvider postViewAnalyticsData={POST_VIEW_ANALYTICS_DATA} size={SIZE} pParams={P_PARAMS}>
        <AdfoxBannerContainer position={position} />
      </AdfoxContextProvider>
    );
  },
);
