import * as React from 'react';
import { OnboardingTooltip, TPlacement } from '@cian/ui-kit';

import { useChatOnboarding } from './hooks/useChatOnboarding';
import { TChatOnboardingType } from './types';
import { ChatOnboardingContent } from '../../components/ChatOnboardingContent';

interface IChatOnboardingContainerProps {
  children: React.ReactElement;
  type: TChatOnboardingType;
  placement: TPlacement;
}

// TODO: Удалить в CD-226276/CD-225149. Онбординги на месяц.
export const ChatOnboardingContainer: React.FC<IChatOnboardingContainerProps> = ({ children, type, placement }) => {
  const { open, onboardingText, closeHandler } = useChatOnboarding(type);

  if (!open) {
    return children;
  }

  return (
    <OnboardingTooltip
      theme="black"
      content={<ChatOnboardingContent>{onboardingText}</ChatOnboardingContent>}
      placement={placement}
      portalElement={document.getElementById('offers-layout')}
      open={open}
      onClose={closeHandler}
      flip={false}
    >
      <div>{children}</div>
    </OnboardingTooltip>
  );
};
