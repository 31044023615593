import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { requestAuthentication } from '../../actions/authentication';
import { getUser } from '../../selectors/user';
import { TTypedThunkDispatch } from '../../types/thunk';
import { closeConsultantCianChat } from '../../actions/consultantCianChat';
import { requestChat } from '../../actions/consultantCianChat/requestChat';

export function useConsultantCianChat() {
  const dispatch: TTypedThunkDispatch = useDispatch();
  const user = useSelector(getUser);

  const openChat = React.useCallback(() => {
    if (!user.isAuthenticated) {
      dispatch(
        requestAuthentication({
          onSuccess: () => requestChat(),
          onCloseNoAuth: closeConsultantCianChat,
        }),
      );

      return;
    }

    dispatch(requestChat());
  }, [dispatch, user]);

  return React.useMemo(
    () => ({
      openChat,
    }),
    [openChat],
  );
}
