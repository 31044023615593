import { IApplicationState } from '../../types/redux';
import { getNewbuildingByIdSelector } from './getNewbuildingByIdSelector';

export const getNewbuildingFZ214Selector =
  (newbuildingId: number) =>
  (state: IApplicationState): boolean => {
    const nb = getNewbuildingByIdSelector(newbuildingId)(state);

    return nb.isFz_214;
  };
